export default [
    {
        header: 'Comptabilitat i fiscalitat',
        permission: 'module_accounting',
    },
    {
        title: 'Requeriments',
        route: 'requirements_list',
        icon: 'MailIcon',
        permission: 'module_accounting',
        children: [
            {
                title: 'Crear requeriment',
                route: 'requirements_create',
                permission: 'create_requirements',
            },
            {
                title: 'Llistat requeriments',
                route: 'requirements_list',
                permission: 'list_requirements',
            },
        ],
    },

]
