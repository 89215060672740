export default [
    {
        header: 'Laboral',
        permission: 'module_labor',
    },
    {
        title: 'Tràmits',
        icon: 'ListIcon',
        route: 'formalities_list',
        permission: 'module_labor',
        children: [
            {
                title: 'Crear tràmit',
                route: 'formalities_create',
                permission: 'create_formalities',
                icon: 'FilePlusIcon',
            },
            {
                title: 'Llistat tràmits',
                route: 'formalities_list',
                icon: 'FileTextIcon',
                permission: 'list_formalities',
            },
        ],
    },
]