export default [
    {
        header: 'Finançament',
        permission: 'module_finance',
    },
    {
        title: 'Inversions',
        icon: 'TrendingUpIcon',
        route: 'investments_list',
        permission: 'module_finance',
        children: [
            {
                title: 'Crear Inversió',
                route: 'investments_create',
                permission: 'create_investment',
            },
            {
                title: 'Llistat Inversions',
                route: 'investments_list',
                permission: 'list_investments',
            },
        ],
    },
    {
        title: 'Finançament',
        icon: 'BarChartIcon',
        route: 'financing_list',
        permission: 'module_finance',
        children: [
            {
                title: 'Crear finançament',
                route: 'financing_create',
                permission: 'create_financing',
            },
            {
                title: 'Llistat de finançament',
                route: 'financing_list',
                permission: 'list_financings',
            },
        ],
    },
]