export default [
    {
        title: 'Clients',
        icon: 'UsersIcon',
        route: 'clients',
        permission: 'Dashboard',
        children: [
            {
                title: 'Crear client',
                route: 'clients_create',
                permission: 'create_clients',
                icon: 'UserPlusIcon',
            },
            {
                title: 'Llistat clients',
                route: 'clients_list',
                permission: 'list_clients',
                icon: 'UserIcon',
            },
        ],
    },
]
