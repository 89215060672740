export default [
    {
        header: 'Assegurances',
        permission: 'module_insurance',
    },
    {
        title: 'Pòlisses',
        icon: 'PocketIcon',
        route: 'policies_list',
        permission: 'module_insurance',
        children: [
            {
                title: 'Crear pòlissa',
                route: 'policies_create',
                permission: 'create_policies',
                icon: 'FilePlusIcon',
            },
            {
                title: 'Llistat pòlisses',
                route: 'policies_list',
                icon: 'FileTextIcon',
                permission: 'list_policies',
            },
        ],
    },
]