export default [
    {
        header: 'Mercantil i Herències',
        permission: 'module_trading',
    },
    {
        title: 'Escriptures',
        icon: 'ShieldIcon',
        route: 'scriptures_list',
        permission: 'module_trading',
        children: [
            {
                title: 'Crear tràmit',
                route: 'scriptures_create',
                permission: 'create_scripture',
            },
            {
                title: 'Llistat tràmits',
                route: 'scriptures_list',
                permission: 'list_scriptures',
            },
        ],
    },
    {
        title: 'Herències',
        icon: 'PieChartIcon',
        route: 'inheritances_list',
        permission: 'module_trading',
        children: [
            {
                title: 'Crear tràmit',
                route: 'inheritances_create',
                permission: 'create_inheritance',
            },
            {
                title: 'Llistat tràmits',
                route: 'inheritances_list',
                permission: 'list_inheritances',
            },
        ],
    },
    {
        title: 'Mercantil',
        icon: 'PackageIcon',
        route: 'inheritances_list',
        permission: 'module_trading',
        children: [
            {
                title: 'Crear tràmit',
                route: 'trading_create',
                permission: 'create_trading',
            },
            {
                title: 'Llistat tràmits',
                route: 'trading_list',
                permission: 'list_trading',
            },
        ],
    },
]